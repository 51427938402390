import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Gallery from '../components/Gallery';
import TitleImg from '../components/TitleImg';

// eslint-disable-next-line
export const GalleryPageTemplate = ({ title, images }) => {
  console.log('images', images);
  return (
    <div>
      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-vcentered is-centered">
              <div className="column is-10 is-offset-1 is-centered-content">
                <TitleImg
                  title={title}
                  titleClass={'gallery-title'}
                  imgSrc={'/img/gallery-title-img.svg'}
                  imgClass={'gallery-title-img'}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Gallery images={images} />
    </div>
  );
};

GalleryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.any
};

const GalleryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <GalleryPageTemplate
        title={frontmatter.title}
        images={frontmatter.images}
      />
    </Layout>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default GalleryPage;

export const galleryPageQuery = graphql`
  query GalleryPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "gallery-page" } }) {
      frontmatter {
        title
        images {
          image {
            publicURL
          }
          alt
        }
      }
    }
  }
`;
