import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This CSS file is important

const Gallery = ({ images }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const imgSrcs = images.map((img) => img.image.publicURL);

  return (
    <div className="gallery">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry gutter="5px">
          {images.map((img, i) => (
            <img
              className="gallery-img"
              key={i}
              src={img.image.publicURL}
              alt={`Gallery ${img.alt}`}
              style={{ width: '100%', display: 'block' }}
              onClick={() => {
                setPhotoIndex(i);
                setIsOpen(true);
              }}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
      {isOpen && (
        <Lightbox
          mainSrc={imgSrcs[photoIndex]}
          nextSrc={imgSrcs[(photoIndex + 1) % imgSrcs.length]}
          prevSrc={imgSrcs[(photoIndex + imgSrcs.length - 1) % imgSrcs.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgSrcs.length - 1) % imgSrcs.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgSrcs.length)
          }
        />
      )}
    </div>
  );
};

export default Gallery;
